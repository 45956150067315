<script setup lang="ts">
import type { PurchaseCondition, IPurchaseListItem } from '~/layers/booking/types/purchase-condition';
import { usePaymentConditionsStore } from '~/layers/booking/stores/paymentConditionsStore';

interface Props {
    purchaseLabel: string;                    // Заголовок для радиокнопки
    purchaseList: IPurchaseListItem[];        // список для отображения условий
    itemPurchaseCondition: PurchaseCondition; // способ покупки у этой радиокнопки
    pageLink?: string;                        // ссылка на страницу с деталями
    period: number | null;                    // количество дней бронирования
}

const props = withDefaults(defineProps<Props>(), {
    pageLink: ''
});

const { t } = useI18n();
const emit = defineEmits(['change']);

const paymentConditionsStore = usePaymentConditionsStore();
const { purchaseCondition } = storeToRefs(paymentConditionsStore);

const { createDayText } = useBookingPurchaseCondition();

const handleChange = () => {
    purchaseCondition.value = props.itemPurchaseCondition;
    emit('change');
};
</script>

<template>
    <div :class="['page-card', { 'active': purchaseCondition === itemPurchaseCondition}]">
        <v-radio 
            :label="purchaseLabel"
            :value="itemPurchaseCondition"
            @change="handleChange">
        </v-radio>
        <div v-if="period" class="page-card__period">
            {{ $t('booking.client.purchase_conditions.items.bookingpPeriod') }}&nbsp;
            <span>&bull;</span>&nbsp;
            {{ period }}&nbsp;
            {{ createDayText(period).toLowerCase() }}
        </div>
        <div v-if="purchaseList.length" class="page-card__list">
            <div 
                v-for="item in purchaseList"
                :key="item.icon"
                class="list-item"
            >
                <i :class="item.icon"></i>
                <div class="list-item__text">{{ item.text }}</div>
                <div class="list-item__value">{{ item.value }}</div>
            </div>
        </div>
        <NuxtLink 
            v-if="pageLink" 
            :to="pageLink"
            class="page-link"
        >
            {{ t('forms.shared.phrases.setup') }}
        </NuxtLink>
    </div>
</template>

<style scoped lang="scss">
.page-card {
    position: relative;
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 100%;

    border-radius: 16px;
    border: 1px solid $border-color;
    background: $main--contrast-bg-color;

    &.active {
        border: 1px solid $color-primary03;
    }

    &__list {
        margin: 0 -16px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: calc(100% + 24px);
    }

    &__period {
        position: absolute;
        right: 16px;
        top: 26px;
        padding: 2px 6px;
        background-color: $color-inverse;
        border-radius: 12px;
        color: $color-neutral04;
        font-size: 12px;
        line-height: 16.2px;
        letter-spacing: -0.01em;

        @include responsive( 'mobile' ) {
           position: static;
        }
    }

    :deep(.v-radio) {
        margin: 0 -8px;

        .v-label {
            letter-spacing: -0.01em;
        }
    }
}

.list-item {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    width: calc(50% - 40px);

    @include responsive( 'mobile' ) {
        width: 50%;
    }

    @include responsive( 'mobile-small' ) {
        width: 100%;
    }

    i {
        font-size: 21px;
        line-height: 1;
        color: $color-inverse;

        &.ruble {
            font-family: $ff-regular;
            font-style: normal;
            width: 20.84px;
            display: flex;
            justify-content: center;
            
            &:before {
                content: "\20BD";
            }
        }
    }

    &__text {
        margin-left: 16px;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: -0.01em;
        color: $color-neutral03;
    }

    &__value {
        margin-left: 16px;
        flex-grow: 1;
        text-align: right;
        font-size: 14px;
        line-height: 17.5px;
        letter-spacing: -0.01em;
        color: $color-inverse02;
    }
}

.page-link {
    color: $color-inverse02;
}
</style>